/*Спецификация*/
body {
	font-family: 'PT Sans', sans-serif;
	font-size: 16px;
	color: #000;
	overflow-x: hidden!important;
}
.container {
	width: 980px;
}
p {
	padding: 0;
	margin: 0;
}
h1 {
	font-family: 'Merriweather', serif;
	font-size: 30px;
	font-weight: 900;
	color: #fff;
	text-transform: uppercase;
	margin-top: 195px;
	margin-bottom: 20px;
	line-height: 45px;
}
h2  {
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 95px;
	text-align: center;
	letter-spacing: 1px;
}

/*Первый экран*/
.header {
	background: #fff url(../img/bg-fon.jpg) center 60px no-repeat;	
	background-size: cover;
}
.overlay-head {
	background: #f7f7f7;
	width: 100%;
	height: 95px;
	padding-top: 26px;
	padding-bottom: 22px;
}
.item {
	width: 260px;
}
.item-text {
	width: 230px;
}
.item-phone {
	margin-left: 20px;
	margin-top: -9px;
}
.item__text {
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
}
.item-adress {
	margin-left: -25px;
}
.adress__text {
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
}
.phone__text {
	font-size: 20px;
	font-weight: 700;
}
.icon-up {
	margin-top: 5px;
	margin-right: 5px;
}
.headhesive {
	transform: translateY(-100%);
	position: fixed;	
	width: 950px;
	transition: 0.7s;
	left: 50%;
	margin-left: -477px;	
}
.headhesive--stick {
	transform: translateY(0);
	z-index: 500;
}
.headhesive--stick .link {
	margin-top: 3px;
	margin-bottom: 0px;
}
.headhesive--stick.menu {
	background: rgba(5,24,24,0.6);
}
.menu {
	background: #031516;
	border-radius: 0px 0px 15px 15px;
}
.menu__item li {
	list-style-type: none;
}
.link {
	margin: 21px 49px 21px 0px;

}
.link:last-child {
	margin-right: 0px;
}
.link__link {
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
}
.link__link:hover {
	text-decoration: none;
	color: #b40404;
	font-weight: 700;
}
.link__link:activ {
	text-decoration: none;
	color: #fff;
}
.link__link:focus {
	text-decoration: none;
	color: #fff;
}
.main-paragraph {
	font-family: 'Merriweather', serif;
	font-size: 20px;
	font-weight: 700;
	color: #fff;
}
.main-text {
	font-family: 'Merriweather', serif;
	font-size: 20px;
	font-weight: 900;
	color: #fff;
	text-transform: uppercase;
}
.form-head {
	background: rgba(121,147,148,0.8);
	border-radius: 10px;
	padding: 30px;
	margin-top: 50px;
	margin-bottom: 140px;
}
.form-head__caption {
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}
.form-head__text {
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	margin-bottom: 25px;
	line-height: 20px;
}
.form-head__input {
	font-size: 15px;
	font-weight: 400;
	color: #929292;
	padding: 15px 0px 15px 21px;
	width: 100%;
	background: #e6e6e6;
	border-radius: 7px;
	outline: none;
	border: none;
	margin-bottom: 15px;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
}
.btn {
	font-family: 'PT Sans', sans-serif;
	background: #d50202;
	border-radius: 7px;
	border: none;
	outline: none;
	color: #fff;
	text-transform: uppercase;
	font-size: 19px;
	font-weight: 700;
	padding-top: 14px;
	padding-bottom: 14px;
	width: 100%;
	text-align: center;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
	margin-bottom: 20px;
}
.text-down {
	margin: 0px auto;
	display: block;
	width: 240px;
}
.fa-lock {
	font-size: 11px;
	margin-top: 2px;
	margin-right: 5px;
}
.text-down__text {
	font-size: 11px;
	font-weight: 400;
	text-transform: uppercase;
}
/*Второй экран*/
.product {
	background: url(../img/bg-fon-2.png) center top no-repeat;
	background-size: cover;
	margin-top: -100px;
	padding-bottom: 100px;
}
.caption-product {	
	width: 300px;
	margin: 0px auto;
	display: block;
	margin-bottom: 50px;	
}
.line-caption {
	width: 60px;	
	border-bottom: 1px solid #000;
	margin: 0px auto;
	display: block;
}
.bloc-box {
	width: 140px;
	margin: 0px auto;
	display: block;
}
.bloc-box__img {
	margin: 0px auto;
	display: block;
	margin-bottom: 5px;
}
.bloc-box__caption {
	text-align: center;
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 10px;
	line-height: 20px;
}
.bloc-caption__line {
	border-bottom: 1px dashed #d50202;
	width: 30px;
	margin: 0px auto;
	display: block;
}
/*Третий экран*/
.sold {
	background: url(../img/bg-fon-3.jpg) center top no-repeat;
	background-size: cover;
	padding-bottom: 90px;
}
.caption-sold__caption {
	color: #fff;
}
.caption-sold__line {
	border-bottom: 1px solid #fff;
}
.box-product-1 {
	background: #f7f7f7;
	padding-top: 40px;
	padding-bottom: 40px;
	border-radius: 10px 10px 0px 0px;
}
.box-product-1__img {
	margin: 0px auto;
	display: block;
} 
.box-product-2 {
	background: #234d4e;
	border-radius: 0px 0px 10px 10px;
	padding: 35px 40px 45px 40px;
	margin-bottom: 30px;
}
.box-product-2__caption {
	font-size: 20px;
	color: #fff;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
}
.box-product-2__text {
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	text-align: center;
	line-height: 20px;
}
.box-product-2__btn {
	background: #d50202;
	border-radius: 10px;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 0px auto;
	display: block;
	color: #fff;
	text-transform: uppercase;
	padding: 10px 25px;
	letter-spacing: 1px;
	margin-top: 20px;
	box-shadow: 1px 1px 1px rgba(0,0,0,0.6);
	font-weight: 700;
}
.box-product-2__btn:hover {	
	border: none;
	outline: none;
	background: #fff;
	color: #234d4e;
	font-weight: 700;
}
.box-product-2__btn:activ {	
	border: none;
	outline: none;
}
.box-product-2__btn:focus {	
	border: none;
	outline: none;
}
.form-head-2 {
	background: #f7f7f7;
	width: 440px;
	padding: 50px 40px;
}
.box-form__caption {
	font-size: 18px;
	font-weight: 700;
	color: #d50202;
	text-transform: uppercase;
	text-align: center;
}
.box-form__paragraph {
	font-size: 19px;
	font-weight: 700;
	color: #000;
	text-align: center;
	line-height: 20px;
    margin-bottom: 15px;
    margin-top: 10px;
}
.labe-inp {
	font-size: 16px;
	font-weight: 700;
}
.form-head__input-check:focus {
	outline: none;
}
#overlay {
	background: rgba(0,0,0,0.6);
	width: 100%;
	height: 5000px;
	position: absolute;
	left: 0;	
	top: 0;

}
.open {
	background: #fff;
	width: 500px;
	height: 220px;
    margin: 27% auto;
    padding: 75px 44px 80px 44px;
    border-radius: 20px;
}

.box-form-act {
	font-size: 19px;
	font-weight: 700;
	color: #d50202;
	text-transform: uppercase;
	text-align: center;
}
.box-form-act-text {
	font-size: 19px;
	font-weight: 700;
	text-align: center;
}
.open-down {
	background: #f7f7f7;
	border-radius: 10px;
	padding: 60px 40px;
	width: 500px;
}
.open-down__caption {
	font-size: 19px;
	font-weight: 700;
	color: #d50202;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 20px;
}
.open-down__text {
	font-size: 19px;
	font-weight: 700;
	text-align: center;
}
/*Четвертый экран*/
.about {
	background: #ebebeb url(../img/bg-fon-4.jpg) center top no-repeat;
	background-size: cover;
	padding-bottom: 145px;
}
.box-about {

}
.box-about__caption {
	font-size: 18px;
	font-weight: 700;
}
.box-about__text {
	font-size: 16px;
	font-weight: 400;
}
.box-about-img__img {
    margin-top: -30px;
    margin-left: -60px;
}
.text-capt-result {
	font-size: 18px;
	font-weight: 700;
	margin-top: 30px;
	margin-bottom: 25px;
}
.text-capt-result-1 {
	margin-top: -25px;
}
.text-capt-result-2 {
	font-size: 16px;
}
.number {

}
.number__number {
	font-size: 60px;
	font-weight: 400;
	color: #d50202;
	margin-left: 25px;
}
.text-number {
	margin-left: 12px;
}
.text-number__caption {
	font-size: 25px;
	font-weight: 700;
	margin-bottom: -8px;
	margin-top: 10px;
}
.text-number__text {
	font-size: 16px;
	font-weight: 700;
	line-height: 16px;
}

/*Пятый экран*/
.work {
	background: url(../img/bg-fon-5.png) center top no-repeat;
	background-size: cover;
	margin-top: -100px;
	padding-bottom: 170px;
	padding-top: 70px;
}
.wrapper-work__img {
	margin: 0px auto 20px;
	display: block;
}
.wrapper-work__caption {
	text-align: center;
	font-weight: 700;
	font-size: 17px;
	text-transform: uppercase;
}
.wrapper-work__paragraph {
	text-align: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}
/*Шестой экан*/
.price {
	background: url(../img/bg-fon-10.png) center top no-repeat;
	background-size: cover;
	margin-top: -130px;
	padding-top: 50px;
	padding-bottom: 140px;
}
.caption-product__caption_span {
	color: #d50202;
}
.wrap-price {
	position: relative;
}
.wrap-price__img-1 {
	position: absolute;
	top: -25px;
	left: -554px;
}
.wrap-price__img-2 {
	position: absolute;
	top: -25px;
	right: -624px;
}
.wrap-price-bloc-up {

}
.wrap-price-bloc {
	
}
.wrap-price-bloc__left-up {
	text-align: center;
	border-radius: 10px 0px 0px 0px;
	font-weight: 700;
	letter-spacing: 1px;
}
.wrap-price-bloc__left {
	background: #234d4e;
	width: 70%;
	color: #fff;
	margin-right: 1px;
	margin-bottom: 1px;
	text-transform: uppercase;
	padding: 9px 20px;
}
.wrap-price-bloc__right-up {
	text-transform: uppercase;
	border-radius: 0px 10px 0px 0px;
	font-weight: 700;
	letter-spacing: 1px;
}
.wrap-price-bloc__right {
	background: #234d4e;
	width: 30%;
	color: #fff;
	text-align: center;
	padding: 8px 10px;
	margin-bottom: 1px;
}
.wrap-price-bloc-down {

}
.wrap-price-bloc__left-down {
	border-radius: 0px 0px 0px 10px;
}
.wrap-price-bloc__right-down {
	border-radius: 0px 0px 10px 0px;
}
.price-btn {
	background: #d50202;
	text-transform: uppercase;
	color: #fff;
	margin: 0px auto;
	display: block;
	border: none;
	outline: none;
	padding: 10px 50px;
	border-radius: 10px;
	font-weight: 700;
	letter-spacing: 1px;
	margin-top: 30px;
	text-decoration: none;
	width: 176px;
}
.price-btn:hover {
	background: #fff;
	color: #d50202;
	outline: none;
	border: none;
	text-decoration: none;
}
.price-btn:active {
	outline: none;
	border: none;
	background: #d50202;
	color: #fff;
}
.price-btn:focus {
	border: none;
	outline: none;
	background: #d50202;
	color: #fff;
}
/*Седьмой кран*/
.map {
	position: relative;
	background: #dcdcdc;
}
.wrap-contacts {
	position: absolute;
	background: rgba(50,50,50,0.96);
	border-radius: 10px;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.7);
	padding: 40px 35px;
	z-index: 100;
	top: -45px;
	left: 0;
	right: 0;
}
.wrap-contacts__caption {
	font-weight: 700;
	font-size: 20px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
}
.wrap-contacts__line {
	width: 60px;
	margin: 0px auto;
	display: block;
	border-bottom: 1px solid #fff;
	margin-bottom: 15px;
}
.icon-map {
	color: #fff;
}
.adress-contacts__text {
	color: #fff;
	font-size: 16px;
	margin-bottom: 8px;
}
.phone-contacts {
	width: 200px;
	margin: 0px auto;
	display: block;
	margin-left: 18px;
}
.phone-contacts__text {
	color: #fff;
	font-size: 18px;
	text-align: center;
}
/*Футер*/
.footer {
	background: #000 url(../img/bg-footer.jpg) center top no-repeat;
	background-size: cover;
}
.footer-logo {
	width: 195px;
	margin: 0px auto;
	display: block;
	padding-top: 50px;
	padding-bottom: 50px;
}
.footer-logo__img {
	margin-bottom: 5px;
}
.footer-logo__text {
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	margin-left: 5px;
}